.errorValues {
	display: block;
}
.moreElement {
	display: none;
}

.showMore:focus ~ .moreElement {
	display: block;
}
