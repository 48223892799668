.ant-table-column-sorter {
	display: none;
}

.ant-checkbox-inner::after {
	display: none;
}

.ant-table-tbody > tr > td {
	border: 1px solid #f4f4f4;
}

th {
	width: 200px;
}

.ant-upload-list-item-name {
	width: 40px;
}


.custom-column-visibility-width {
  width: auto;
	max-width: 850px;
}
.ant-select-selector{
	height:45px !important;
	margin-right: 2px;
}

